import { render, staticRenderFns } from "./secondBox.vue?vue&type=template&id=ac5ca78a&scoped=true"
import script from "./secondBox.vue?vue&type=script&lang=js"
export * from "./secondBox.vue?vue&type=script&lang=js"
import style0 from "./secondBox.vue?vue&type=style&index=0&id=ac5ca78a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_pudllrsdjtxfushci5c2mqnple/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac5ca78a",
  null
  
)

export default component.exports