<template>
  <div id="TechContent">

    <div class="title">
      <h3>登陆注册</h3>
    </div>
<!--    <div class="content"-->
<!--         v-html="info.content">-->
<!--    </div>-->
  </div>
</template>

<script>
// import {getNewInfo} from '../../api/news'
// import {mapState} from 'vuex'

export default {
  props: ['title'],
  computed: {
    // ...mapState([
    //   'TabIndex'
    // ])
  },
  data: () => ({
    index: '',
    NewTitle: '',
    newHTML: '',
    info: {
      title: '',
      content: ''
    }
  }),
  watch: {
    // TabIndex() {
    //   this.index = this.$route.query.index
    //   this.changeHTML()
    // }
  },
  mounted() {
    // this.index = this.$route.query.index
    // this.changeHTML()
  },
  methods: {
    // 获取详情
    // async getInfo(id) {
    //   const res = await getNewInfo({
    //     id
    //   })
    //   this.info = res.data
    // },
    // changeTitle () {
    //   if (this.title === '课程概述') {
    //     this.NewTitle = '课程体系概述'
    //   } else if (this.title === '课程体系') {
    //     this.NewTitle = '（CIIT）课程体系'
    //   } else if (this.title === '入校方案') {
    //     this.NewTitle = 'CIIT课程入校方案'
    //   } else if (this.title === '师资保障') {
    //     this.NewTitle = 'CIIT师资保障'
    //   }
    // },
    // changeHTML() {
    //   if (this.index == 0) {
    //     this.getInfo(9)
    //     // this.newHTML = this.html1
    //   } else if (this.index == 1) {
    //     this.getInfo(10)
    //     // this.newHTML = this.html2
    //   } else if (this.index == 2) {
    //     this.getInfo(11)
    //     // this.newHTML = this.html3
    //   } else if (this.index == 3) {
    //     this.getInfo(12)
    //     // this.newHTML = this.html4
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
#TechContent {
  min-height: 544px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
}
</style>
