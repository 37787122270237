<template>
  <div class="info">
    <!-- <div style="width: 40%; margin: auto; margin-bottom: 20px">
      <img :src="$route.query.subject_img" alt="课程详情图片" />
    </div> -->

    <div class="upBox">
      <img :src="$route.query.subject_img" :alt="title" />
      <div v-if="$route.query.index != 1" class="imgText">
        <p style="margin-bottom: 0; text-align: center; font-size: 16px">
          <b>{{ title }}</b>
        </p>
      </div>
    </div>
    <h5>{{ title }}</h5>

    <div class="detalis" v-if="this.$route.query.index != 1">
      <h6>
        {{
          this.$route.query.index == 0
            ? "学科介绍"
            : this.$route.query.index == 1
            ? "该证书简介"
            : "专业介绍"
        }}
      </h6>
      <div style="padding-left: 20px; padding-right: 20px">
        <p>{{ details ? details : "暂无" }}</p>
      </div>
    </div>

    <div style="margin-top: 20px" v-if="this.$route.query.index != 1">
      <h6>{{ this.$route.query.index == 0 ? "相关专业" : "相关课程" }}</h6>

      <div
        class="ListBoxs"
        v-if="list && list.length != 0 && $route.query.index != 1"
      >
        <div style="padding: 20px">
          <el-row :gutter="20">
            <div class="formBox" v-for="(item, index) in list" :key="index">
              <el-col :span="8">
                <div class="subjectBox" @click="goNewsInfo(item, list)">
                  <div
                    ref="imgBox"
                    :class="isheight ? 'imgBox2' : 'imgBox1'"
                    class="imgBox1"
                  >
                    <img :src="item.img" :alt="item.title" />
                    <div
                      style="
                        position: absolute;
                        z-index: 2;
                        left: 0;
                        top: 50px;
                        color: #fff;
                        width: 100%;
                      "
                    >
                      <p
                        style="margin: 0; text-align: center; font-size: 16px"
                        v-if="!item.course_id"
                      >
                        <b>{{ item.title }}</b>
                      </p>
                    </div>
                  </div>
                  <p style="margin: 0; padding-left: 20px; padding-top: 10px">
                    <b>{{ item.title }}</b>
                  </p>

                  <div class="textBox">
                    <p
                      class="textDeatils"
                      style="color: #9297b5"
                      :title="item.details"
                    >
                      {{ item.details }}
                    </p>
                  </div>
                </div>
              </el-col>
            </div>
          </el-row>
        </div>
      </div>
      <p
        v-else
        style="padding-left: 20px; padding-right: 20px; font-size: 14px"
      >
        未开放相关课程
      </p>
    </div>

    <div v-if="$route.query.index != 2">
      <h6>证书介绍</h6>
      <div style="font-size: 14px; text-indent: 2em">
        <p>
          “通信与信息技术创新人才培养工程”将按照统一的标准（包括：教学标准、师资标准、考试标准、实践标准、运营标准等）开展工作，对于培训考核合格的学员，将颁发相应专业“职业技术水平证书”。该证书表明学员接受了本专业课程的职业培训，具备一定的实际工作能力，可作为岗位聘用、任职、定级、晋升依据。
        </p>
        <p>
          <b
            >本证书不等同于国家职业资格证书，不涉及行业准入标准，学员自愿参加培训学习，无任何强制性要求。</b
          >
        </p>
        <p>证书分为三个等级：</p>
        <p>¢初级：能够运用基本技能独立完成本职业的常规工作。</p>
        <p>
          ¢中级：能够熟练运用基本技能独立完成本职业的常规工作，并在特定情况下，能够运用专门技能完成较为复杂的工作；能够与他人进行合作。
        </p>
        <p>
          ¢高级：能够熟练运用基本技能和专门技能完成较为复杂的、非常规性的工作；掌握本职业的关键技能技术；能够独立处理和解决问题；在技能技术方面有创新；能组织指导他人进行工作；能培训一般操作人员；具有一定的管理能力。
        </p>
      </div>

      <div style="margin-top: 20px" class="certificateBox">
        <img src="subjectImg/certificate.jpg" alt="" style="width: 30%" />
        <img src="subjectImg/two.jpg" alt="" style="width: 30%" />
        <img src="subjectImg/three.jpg" alt="" style="width: 30%" />
        <img src="subjectImg/four.jpg" alt="" style="width: 30%" />
        <img src="subjectImg/five.jpg" alt="" style="width: 30%" />
        <img src="subjectImg/six.jpg" alt="" style="width: 30%" />
      </div>
    </div>
    <div v-if="$route.query.index == 2">
      <p style="padding-left: 20px; padding-right: 20px; font-size: 14px">
        报名咨询 刘主任 18701377690 15810657608
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "诚意在线",
        number: 60,
        person: 100,
      },
      list: [],
      details: "",
      title: "",
      isheight: false,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    /**
     * 获取信息
     * @returns{arr}
     */
    async getInfo() {
      console.log("this.$route", this.$route.query);
      let index = this.$route.query.index;
      let info = this.$route.query.info;
      this.details = info.details;
      this.title = info.title;
      if (index == 0) {
        this.list = info.certificate;
      } else if (index == 1) {
        this.list = info.course;
        this.isheight = true;
        this.$route.query.subject_img = "subjectImg/certificate.jpg";
      } else if (index == 2) {
        this.list = this.$route.query.arr;
      }
    },

    goNewsInfo(item) {
      console.log("item", item);

      if (this.$route.query.index == 0) {
        this.$route.query.index = 2;
        this.$route.query.arr = item.course;
        this.getInfo();
        this.title = item.title;
        this.$route.query.subject_img = item.img;
        this.details = item.details;
        this.isheight = true;
      } else if (this.$route.query.index != 0) {
        this.$message({
          type: "warning",
          message: "报名后可以学习",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  h5 {
    text-align: center;
    margin-bottom: 20px;
  }
  h6 {
    border-left: 10px solid skyblue;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }

  .detalis {
    margin-top: 20px;
    font-size: 14px;
  }
  .patter {
    margin-top: 20px;
    font-size: 14px;
  }
  .textBox {
    padding: {
      left: 20px;
      right: 20px;
      top: 10px;
    }
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .textDeatils {
    position: relative;
    line-height: 20px;
    max-height: 60px;
    overflow: hidden;
  }

  .textDeatils::after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 10px;
    padding-left: 40px;
    background: -webkit-linear-gradient(left, transparent, #fff 55%);
    background: -o-linear-gradient(right, transparent, #fff 55%);
    background: -moz-linear-gradient(right, transparent, #fff 55%);
    background: linear-gradient(to right, transparent, #fff 55%);
  }

  .subjectBox {
    box-shadow: 1px 1px 3px 3px #c1c1c1;
    margin-bottom: 20px;
    height: 230px;
    cursor: pointer;
  }

  .imgText {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 50px;
    color: #fff;
    width: 100%;
  }

  .upBox {
    width: 40%;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
  }

  .imgBox1 {
    height: 50%;
    width: 100%;
    position: relative;
  }
  .imgBox2 {
    height: 80%;
    width: 100%;
    position: relative;
  }

  .certificateBox {
    padding-left: 10px;
    img {
      margin: 10px;
    }
  }
}
</style>