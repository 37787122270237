<template>
  <div id="projectContent">
    <div class="list" v-if="indexMap == 0 && path == '/help/problem'">
      <ProList />
    </div>
    <div v-else-if="indexMap == 0 && path == '/help/problem/info'">
      <ProContent />
    </div>

    <div class="" v-else-if="indexMap == 2">
      <ContactUs />
    </div>

    <div v-else>
      <h3 v-if="indexMap != 5">{{ NewTitle }}</h3>
      <div class="baiDuMap" v-if="indexMap == 5">
        <p style="margin: 20px">
          位置：北京市西城区广安门外南滨河路甲25号金工宏洋大厦B座320
        </p>
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          @ready="handler"
          :scroll-wheel-zoom="true"
          :mapClick="false"
          ak="lhaYXHeYl3GhecbjMm7vbLKvIQcsPbdl"
        >
          <!-- 比例尺控件，注册此组件才会在地图上显示比例尺 -->
          <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
          <!-- 缩放控件，注册此组件才会显示拖放进度 -->
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <div v-for="(marker, indexMap) in markers" :key="marker.id">
            <!-- 标记点组件 -->
            <bm-marker
              :position="{ lng: marker.lng, lat: marker.lat }"
              :title="marker.servestationname"
              @click="infoWindowOpen(marker)"
            >
              <!-- 信息窗体组件 -->
              <!--              <bm-info-window :title="marker.servestationname"-->
              <!--                              :position="{ lng: marker.lng, lat: marker.lat }" :show="marker.showFlag"-->
              <!--                              @close="infoWindowClose(marker)">-->
              <!--                <p>-->
              <!--                  {{ marker.servestationaddress }}-->
              <!--                </p>-->
              <!--                <p>服务车型：{{ marker.brandandcartype }}</p>-->
              <!--                <p>联系人：{{ marker.handler }}</p>-->
              <!--                <p>联系热线：{{ marker.phone }}</p>-->
              <!--              </bm-info-window>-->
              <!-- 标签组件 -->
              <bm-label
                :content="indexMap + 1 + ''"
                :labelStyle="{
                  color: '#fff',
                  fontSize: '12px',
                  background: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                }"
                :offset="{ width: indexMap >= 9 ? 0 : 4, height: 2 }"
              />
            </bm-marker>
          </div>
        </baidu-map>
      </div>

      <div class="content" v-html="newHtml" v-else></div>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue' // 百度地图组件
import BmScale from 'vue-baidu-map/components/controls/Scale' // 比例尺组件
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmMarker from 'vue-baidu-map/components/overlays/Marker' // 标记点的组件
import BmLabel from 'vue-baidu-map/components/overlays/Label' // 标签组件，展示在标记点中的数字
// import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow' // 信息窗体
import { mapState } from 'vuex'
import ProList from './proList.vue'
import ProContent from './proContent.vue'
import ContactUs from './contactUs.vue'

import { getNewInfo } from '../../api/news'

export default {
  components: {
    ProList,
    ProContent,
    ContactUs,
    BaiduMap,
    BmScale,
    BmNavigation,
    BmMarker,
    // BmInfoWindow,
    BmLabel,
  },
  data: () => ({
    center: {
      lng: 116.354749,
      lat: 39.888135,
    },
    zoom: 3,
    // 地图标记点数据
    markers: [
      {
        lng: 116.354749,
        lat: 39.888135,
        showFlag: true,
      },
    ],
    path: '',
    index: 0,
    indexMap: 0,
    newHtml: '',
    newTitle: '',
    NewTitle: '',
  }),
  computed: {
    ...mapState(['TabIndex', 'title']),
  },
  watch: {
    $route: {
      handler() {
        this.path = this.$route.path
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
    TabIndex(newIndex) {
      this.index = newIndex
      this.indexMap = this.$route.query.index
      console.log('this.indexMap', this.indexMap)
      this.changeHTML()
    },
    title(newTitle) {
      this.newTitle = newTitle
      // this.changeHtml()
    },
  },
  created() {
    this.path = this.$route.path
    this.newTitle = this.title
    this.index = this.$route.query.index
  },
  mounted() {
    this.indexMap = this.$route.query.index
    this.changeHTML()
    console.log('this.indexMap', this.indexMap)
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id,
      })
      this.NewTitle = res.data.title
      this.newHtml = res.data.content
    },
    changeHTML() {
      if (this.indexMap == 1) {
        this.getInfo(25)
      } else if (this.indexMap == 2) {
        this.getInfo(26)
      } else if (this.indexMap == 3) {
        this.getInfo(27)
      } else if (this.indexMap == 4) {
        this.getInfo(28)
      }
    },
    // 地图初始化完成回调
    handler() {
      this.center.lng = 116.354749
      this.center.lat = 39.888135
      this.zoom = 19
    },
    // 关闭标记详情
    infoWindowClose(marker) {
      marker.showFlag = false
    },
    // 打开标记详情
    infoWindowOpen(marker) {
      marker.showFlag = !marker.showFlag
    },
  },
}
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}

.baiDuMap {
  height: 400px;
}

.bm-view {
  width: 700px;
  height: 400px;
  margin: 0 auto;
}
</style>
