import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// 按需引入elemnetUi 放心食用
import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Select,
  Carousel,
  CarouselItem,
  FormItem,
  Form,
  Input,
  Pagination,
  Dialog,
  Option,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Table,
  TableColumn,
  Row,
  Col,
  Message,
  MessageBox,
} from 'element-ui'

Vue.component(Button.name, Button)
Vue.component(Select.name, Select)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Carousel.name, Carousel)
Vue.component(FormItem.name, FormItem)
Vue.component(Form.name, Form)
Vue.component(Input.name, Input)
Vue.component(Pagination.name, Pagination)
Vue.component(Dialog.name, Dialog)
Vue.component(Option.name, Option)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Message.name, Message)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

Vue.config.productionTip = false
Vue.prototype.$http = axios
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// app.js
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
//引入 utls.js
import { domain } from './utils/utils'
//引入 common.js
import common from './utils/common'

console.log('环境：', process.env.VUE_APP_BASE_API)
Vue.use(common)
//配置axios
//->1,模块一接口,url地址
axios.defaults.json = domain.Base_API_JSON
//->2,模块二接口,url地址
axios.defaults.api = domain.Base_API_DEV

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
