<template>
  <div class="newAddHomeItem">
    <tab :titleName="title " :list="cityList"/>
    <div class="training">
      <TrainTest :title="title2" :name="'zlhz'"/>
    </div>
    <div class="zjltBox">
      <qutab :titleName="title3 " :list="problemList" :id="'cjwt'"/>
    </div>
  </div>
</template>

<script>
import {getCoopList} from '../../api/coop.js'
import tab from '../home/coop_inst.vue'
import TrainTest from '../home/train_test.vue'
import qutab from './ProblemScroll.vue'
import {getNewList} from "@/api/news";

export default {
  name: "newAddHomeItem",
  components: {
    tab,
    TrainTest,
    qutab
  },
  data: () => ({
    title3: "常见问题",
    title: "合作机构",
    title2: "战略合作",
    cityList: [],
    problemList: [],
  }),
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const res = await getCoopList()
      console.log(res)
      this.cityList = res.data.data
    },
    // 获取新闻列表
    async getNewsLists() {
      const res = await getNewList({
        page_num: 3,
        cate_id: 24,
      })
      this.problemList = res.data.data
    },
  }
}
</script>

<style lang="scss" scoped>
.newAddHomeItem {
  display: flex;
}

.industryNews {
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .industryNewsBox {
    width: 302px;
    height: 156.5px;

    .title {
      display: inline-block;
      border-bottom: 1px #003366 solid;
      margin: 0;
      padding: 5px 0;
      font-weight: 700;
      color: #003366;
    }

    .list {
      width: 100%;
      border-top: 1px #003366 solid;
    }
  }
}

.queryBox {
  margin-left: 10px;
}

.zjltBox {
  margin-left: 10px;
}

.training {
  height: 175px;
}
</style>
