<template>
  <div class="tab">
    <div class="title">
      <p>常见问题</p>
    </div>
    <div class="tablList">
      <vueSeamless :data="problemList"
                   class="seamless-warp">
        <p v-for="(item,index) in problemList"
           :key="index"
           @click="gonewsInfo(index,item.id)">
          {{ item.title }}
        </p>
      </vueSeamless>
      <!--      </vueSeamless>-->
      <!--            <b-list-group class="ListBox">-->
      <!--              <b-list-group-item v-for="(item, index) in problemList" :key="index" class="itemBox"-->
      <!--                                 @click="gonewsInfo(index,item.id,item.picture_url)">-->
      <!--                <p>-->
      <!--                  <a :href="item.file" v-if="item.file !== ''">-->
      <!--                    {{ item.title }}-->
      <!--                  </a>-->
      <!--                </p>-->
      <!--              </b-list-group-item>-->
      <!--            </b-list-group>-->
    </div>
  </div>
</template>

<script>
import {getNewList} from "@/api/news";
import vueSeamless from 'vue-seamless-scroll'

export default {
  components: {
    vueSeamless
  },
  data: () => ({
    problemList: [],
  }),
  computed: {},
  created() {
  },
  mounted() {
    this.getNewsLists()
  },
  methods: {
    async getNewsLists() {
      const res = await getNewList({
        page_num: 10,
        cate_id: 24,
      })
      this.problemList = res.data.data
      console.log('this.problemList', this.problemList)
    },
    // 跳转详情
    gonewsInfo(index, id) {
        this.$router.push(`/help/problem/info?index=0&id=${id}`)
        sessionStorage.setItem('navTitle', '常见问题')
    },
  }
}
</script>

<style lang="scss" scoped>
.tab {
  width: 249px;
  height: 175px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  margin-bottom: 10px;
}

.tablList {
  box-sizing: border-box;
  height: 130px;
  overflow: hidden;
  padding: 0 10px;

  p {
    width: 220px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  p:hover {
    color: red;
  }
}
</style>
