import { render, staticRenderFns } from "./teacherHall.vue?vue&type=template&id=51ec9736&scoped=true"
import script from "./teacherHall.vue?vue&type=script&lang=js"
export * from "./teacherHall.vue?vue&type=script&lang=js"
import style0 from "./teacherHall.vue?vue&type=style&index=0&id=51ec9736&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.11_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_pudllrsdjtxfushci5c2mqnple/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ec9736",
  null
  
)

export default component.exports